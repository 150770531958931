import './loader.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';

const styles = {
    svg: {
        color: 'red',
    }
};

let loader

function show() {
    if (!loader) return console.error('Loader not Mounted');
    loader.setState({ loader_active: true, });
}

function hide() {
    if (!loader) return console.error('Loader not Mounted');
    loader.setState({ loader_active: false, });
}


class CircularIndeterminateUnstyled extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader_active: false,
        }
        loader = this;
    }

    getActiveClass() {
        const loaderClass = this.state.loader_active ? "loader-container-active" : "";
        return loaderClass;
    }

    render() {
        if (!this.state.loader_active) return null;
        return (
            <div className={"loader-container " + this.getActiveClass()}>
                <div className="loader-backdrop " style={{ maxHeight: 1080, maxWidth: 1920 }} />
                <div className="loader" >
                    <CircularProgress className={classNames(this.props.classes.svg)} />
                </div>
            </div>

        )
    }
}

const CircularIndeterminate = withStyles(styles)(CircularIndeterminateUnstyled);
const loader_controls = {
    show,
    hide
}
export default CircularIndeterminate;

export {
    loader_controls as loader
}
