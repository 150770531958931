import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class PreloadImages extends Component {
    constructor(props) {
        super(props);
        this.images_loaded = props.images.reduce((obj, image_url) => {
            obj[image_url] = false;
            return obj;
        }, {});
        this.state = {
            all_loaded: false,
            minimum_delay_reached: false
        };
        if (props.preLoadAction) {
            props.preLoadAction();
        }
    }

    componentDidMount() {
        this.props.images.forEach(image => {
            const img = new Image();
            img.src = image;
            img.onload = this.handleImageLoaded(image);
            img.onerror = error => {
                console.error(
                    `Image with url ${image} could not be found. Skipping it.`
                );
                const imageLoadedFunction = this.handleImageLoaded(image);
                imageLoadedFunction();
            };
        });
        if (this.props.minimumDelay) {
            setTimeout(() => {
                this.setState({ minimum_delay_reached: true }, this.postLoadAction);
            }, this.props.minimumDelay);
        } else {
            this.setState({ minimum_delay_reached: true }, this.postLoadAction);
        }
    }

    handleImageLoaded(image_url) {
        return () => {
            this.images_loaded[image_url] = true;
            const all_loaded = this.props.images.every(
                image_url => this.images_loaded[image_url]
            );
            if (all_loaded) {
                this.setState(
                    {
                        all_loaded: true
                    },
                    this.postLoadAction
                );
            }
        };
    }

    postLoadAction() {
        if (
            this.props.postLoadAction &&
            this.state.all_loaded &&
            this.state.minimum_delay_reached
        ) {
            this.props.postLoadAction();
        }
    }

    getImages() {
        return this.props.images.map(image_url => {
            return (
                <img
                    style={{ display: 'none' }}
                    key={image_url}
                    src={image_url}
                    onLoad={this.handleImageLoaded(image_url)}
                    alt="imageNotFound"
                />
            );
        });
    }

    getContent() {
        return this.props.children ? this.props.children : null;
    }

    render() {
        return this.state.all_loaded && this.state.minimum_delay_reached
            ? this.getContent()
            : null;
    }
}

PreloadImages.propTypes = {
    images: function(props, propName, componentName) {
        const image_prop = props.images;
        const isArray = Array.isArray(image_prop);
        if (!isArray)
            return new Error(
                `${componentName} needs an array of image urls to work. Input is not an array.`
            );
        if (!image_prop.length)
            return new Error(
                `${componentName} needs an array of image urls to work. Input is an empty array.`
            );
    },
    minimumDelay: PropTypes.number
};
