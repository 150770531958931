import { config } from "../config";
import { events } from "../services/events";

const { available_languages } = config;

const available_lang_directions = ["rtl", "ltr"];

Object.values(available_languages).forEach(lang_conf => {
  if (!available_lang_directions.includes(lang_conf.direction))
    throw new Error(`Invalid Direction specified for ${lang_conf.description}`);
  if (!lang_conf.description)
    throw new Error(`No Description specified for ${lang_conf.description}`);
});

let app_language_key,
  app_language_text,
  language_config,
  direction,
  document_body_direction;

function setLanguage(new_language) {
  console.info("Language Change Request", new_language);
  if (new_language === app_language_key) return;
  app_language_key = new_language;

  language_config = available_languages[app_language_key];
  if (!language_config) throw new Error("Invalid Default Language Specified");

  direction = language_config.direction;
  app_language_text = language_config.description;

  setDocumentBodyDirection(direction);
  events.emit("language-change");
}

function initLanguageService() {
  setLanguage(config.default_language);
}

function setDocumentBodyDirection(direction) {
  // if direction has not changed, do nothing
  if (document_body_direction === direction) return;
  // if direction had a previous value remove it from the class list
  if (document_body_direction) {
    document.body.classList.remove(document_body_direction);
  }
  // add the new direction to the class list
  document_body_direction = direction;
  document.body.classList.add(document_body_direction);
}

function getDirection() {
  return direction;
}

function getLanguageKey() {
  return app_language_key;
}

function getLanguageText() {
  return app_language_text;
}

function getAvailableLanguages() {
  return available_languages;
}

export {
  setLanguage,
  getDirection,
  getLanguageKey,
  initLanguageService,
  getLanguageText,
  getAvailableLanguages
};
