import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { getTextsProperty } from '../../localization/get_texts_property';

const styles = (theme) => ({
  card: {
    maxWidth: 380,
    maxHeight: 900,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0px 10px',
    height: '100%',
    width: '85vw',
    boxShadow: 'none',
    borderRadius: 'none'
  },
  content: {
    color: 'white',
    fontSize: 17,
    fontWeight: 500
  },
  questionRemainingTypograph: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
    borderBottom: '1px solid white'
  },
  typography: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 17
  },
  title: {
    height: 0,
    // paddingTop: '56.25%', // 16:9
    textAlign: 'center'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginBottom: 10
  },
  actions: {
    padding: '0px 18px 40px 18px'
  },
  fab: {
    margin: 4,
    backgroundColor: 'white',
    fontWeight: 700,
    fontSize: 15,
    color: 'red',
    minWidth: 280,
    width: 'fit-cotent'
  },
  image: {
    width: 270,
    height: 180
  },
  button: {
    borderRadius: 30,
    width: theme.buttonWidth.width,
    marginTop: 15,
    height: 40,
    fontWeight: 800,
    textTransform: 'none',
    fontSize: 14,
    backgroundColor: 'white',
    color: 'red'
  },
  titleTypography: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 15,
    borderBottom: '1px solid white'
  }
});

//can be stateless function
class QuestionCard extends React.Component {
  render() {
    const {
      classes,
      content,
      answers,
      handleAnswer,
      imageUrl,
      questionNumber
    } = this.props;

    //Render random Div to stop bots
    const randomId = Math.random();
    const randomDiv = Math.floor(Math.random() * 10);

    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems={'center'}
        spacing={1}
      >
        <Grid item>
          <Typography className={classes.titleTypography} component="p">
            {getTextsProperty((text) => text.question_card.question_number)}
            {questionNumber}
            {getTextsProperty((text) => text.question_card.of)}
            30
          </Typography>
        </Grid>
        <Grid item>
          {randomDiv % 2 === 0 ? (
            <Typography className={classes.typography} component="p">
              <span id={randomId+1}></span>
            </Typography>
          ) : (
            ''
          )}
          <Typography className={classes.typography} component="p">
            <span id={randomId}>{content}</span>
          </Typography>
        </Grid>
        <Grid item>
          <img
            className={classes.image}
            src={imageUrl}
            alt={'Question Image'}
          />
        </Grid>
        <Grid item xs={12}>
          <Fab
            onClick={handleAnswer('1')}
            variant="extended"
            aria-label="Answer 1"
            className={classes.fab}
          >
            {answers[0]}
          </Fab>
        </Grid>
        <Grid item xs={12}>
          <Fab
            onClick={handleAnswer('2')}
            variant="extended"
            aria-label="Answer 2"
            className={classes.fab}
          >
            {answers[1]}
          </Fab>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={'row'}
            alignItems={'center'}
            justify={'center'}
            spacing={1}
            style={{ display: '-webkit-box' }}
          >
            <Grid item>
              <Typography className={classes.typography} component="p">
                {getTextsProperty((text) => text.question_card.get)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typography} component="p">
                {questionNumber * 10}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typography} component="p">
                {getTextsProperty((text) => text.question_card.points)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

QuestionCard.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ).isRequired
};

export default withStyles(styles)(QuestionCard);
