import { Router, Route, Switch } from "react-router";

import { createBrowserHistory } from "history";

const history = createBrowserHistory();



let _query = {};

const query = {
  get: key => _query && _query[key]
};

const toURL = (url, q) => {
  _query = q || {};
  history.push(url);
};

export { Router, Route, history, toURL, query, Switch };
