import React, {Component} from "react";
import {withStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

import ReplyCard from "./reply_card";
import QuestionCard from "./question_card";

import {PreloadImages} from "../../components/preload_images";
import LoaderComponent, {loader} from "../../components/loader";

import state from "../../services/state";
import api from "../../services/api/api";


import {tryGet} from "../../utils/try_get";
import {questions_in_each_renewal} from "./config.js";

import {getLanguageKey} from "../../localization/language";

const styles = theme => ({
    logo: {
        width: 100,
        paddingTop: 10
    }
});

class Questions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_reply: false,
            show_reply_error: false,
            error_message: "Fail",
            quizFinished: state.app_data.state === "WRONGEND_GAME_QUIZSTART_GAME_QUIZ_COMPLETEDONGOING_QUIZ_COMPLETED" || state.app_data.state === "CORRECTEND_GAME_QUIZSTART_GAME_QUIZ_COMPLETEDONGOING_QUIZ_COMPLETED\"",
        }
        this.selectAnswer = this.selectAnswer.bind(this);
        this.showNextQuestion = this.showNextQuestion.bind(this);
    }

    componentDidMount() {
        this.props.setToolbarStatus(true);
    }

    getQuestion() {
        return tryGet(o => state.app_data.question.text, "");
    }

    getAnswers() {
        return [
            <span className={this.getAnswerFontSizeClass("1")}>
        {this.getAnswer("1")}
      </span>,
            <span className={this.getAnswerFontSizeClass("2")}>
        {this.getAnswer("2")}
      </span>
        ];
    }

    getAnswerFontSizeClass(i) {
        const answer = this.getAnswer(i);

        const answer_length = answer.length;
        if (answer_length > 30) return "font-1";
        if (answer_length > 20) return "font-2";
        return "font-4";
    }

    getAnswer(i) {
        return tryGet(o => state.app_data.question.answers[i - 1], "");
    }

    removeFirstOccurenceAndBefore(str, searchstr) {
        let index = str.indexOf(searchstr);
        if (index === -1) {
            return str;
        }

        return str.slice((index + 1) + searchstr.length);
    }

    getCard() {
        if (!state.app_data) return null;
        let question = this.getQuestion();

        let questionNumber = state.app_data.user.questions_received;
        const answers = this.getAnswers();
        if (!question) {
            questionNumber = questions_in_each_renewal + 1;
        }

        //reply card
        if (this.state.show_reply || this.state.quizFinished) {
            const result = this.getNormalizedQuestionResult();
            const reply = state.app_data.reply;
            questionNumber = state.app_data.user.questions_received;

            if ((state.app_data.user.questions_received === 30 && state.app_data.question.text === undefined) || this.state.quizFinished) {
                return (
                    <ReplyCard
                        result={result}
                        content={reply}
                        finalReply={true}
                    />
                );
            } else {
                return (
                    <ReplyCard
                        result={result}
                        content={reply}
                        finalReply={false}
                        showNextQuestion={this.showNextQuestion}
                    />
                );
            }
        } else if (question) {
            //check if he exceeded number of questions

            question = this.removeFirstOccurenceAndBefore(question, ':');

            const image_url = this.getImageUrl();
            return (
                <PreloadImages
                    images={[image_url]}
                    preLoadAction={loader.show}
                    postLoadAction={loader.hide}
                    minimumDelay={700}
                >
                    <QuestionCard
                        content={question}
                        answers={answers}
                        handleAnswer={this.selectAnswer}
                        imageUrl={image_url}
                        questionNumber={questionNumber}
                    />
                </PreloadImages>
            );
            // if(questionNumber <= questions_in_each_renewal){
            //     const image_url= this.getImageUrl();
            //     return(
            //         <PreloadImages images={[image_url]} preLoadAction={loader.show} postLoadAction={loader.hide} minimumDelay={700}>
            //             <QuestionCard content={question} answers={answers} handleAnswer={this.selectAnswer} imageUrl={image_url} questionNumber={questionNumber} />
            //         </PreloadImages>
            //     )
            // }
        }
    }

    getNormalizedQuestionResult() {
        const result_lower = tryGet(o => state.app_data.result, "") || "";
        return result_lower.toUpperCase();
    }

    getImageUrl() {
        const public_url = process.env.PUBLIC_URL;
        const image_url = tryGet(
            o => `${public_url}/question_images/${state.app_data.question.url}`,
            `${public_url}/question_images/fallback.jpeg`
        );
        return image_url;
    }

    selectAnswer(i) {
        return async () => {
            loader.show();
            const res = await api.reply({answer: i, question_id: this.getQuestion(), language: getLanguageKey()});

            if (!res.success) {
                //how to handle network error here? Move Snackbar here?
                this.setState({show_reply_error: true});
                return 0;
            }
            // overwrite app data
            state.app_data = res.app_data;
            if (state.app_data.user.questions_received === 30 && state.app_data.question.text === undefined) {
                this.setState({
                    quizFinished: true
                })
            }
            this.setState({show_reply: true});
            return 1;
        };
    }

    showNextQuestion() {
        this.setState({show_reply: false});
    }

    render() {
        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                style={{display: 'inline-table', textAlign: 'center', paddingTop: '20px'}}
            >
                <LoaderComponent/>
                <Grid item>{this.getCard()}</Grid>
            </Grid>

        );
    }
}

export default withStyles(styles)(Questions);
