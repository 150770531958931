module.exports = {
    getQuery: key => {
        const url_params = new URLSearchParams(window.location.search);
        return url_params.get(key);
    },

    getLocation: () => {
        const pathname = window.location.pathname;
        const aid = pathname.slice(1);
        if (['questions', 'homepage','landing-page'].includes(aid)) return null;
        return aid;
    }
}