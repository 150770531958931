import React, { Fragment } from "react";
import PropTypes from "prop-types";

import hotlink_logo from "../../assets/images/hotlink_new_logo.png";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getLanguageText } from "../../localization/language";

import state from "../../services/state";
import { tryGet } from "../../utils/try_get";
import { getTextsProperty } from "../../localization/get_texts_property";

const styles = theme => ({
  card: {
    maxWidth: 380,
    maxHeight: 900,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    margin: "0px 10px",
    height: "100%",
    width: "85vw",
    boxShadow: "none",
    borderRadius: "none"
  },
  content: {
    height: "80%"
  },
  typography_media: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: 25,
    color: "white",
    padding: "0px 16px"
  },
  typography_content: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: 17,
    color: "white"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "auto"
  },
  actions: {
    padding: "0px 18px 20px 18px"
  },
  fab: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    fontWeight: 700,
    fontSize: 15,
    color: "red"
  },
  spacing: [0, 2, 3, 5, 8],
  logo: {
    width: 100,
    paddingTop: 10
  }
});

function getContentRepliesMap(language, finalReply) {
  const prize = tryGet(o => state.app_data.prize, "prize");
  const points = tryGet(o => state.app_data.user.weeklyPoints, "new");

  if (language === "English") {
    if (finalReply) {
      return {
        CORRECT: [
          <Fragment>
            RM0. Excellent! This week's quiz ends here with a total of {points}{" "}
            points for you. Winners are chosen at the end of the week. If you
            win the {prize}, 123 will call you!
          </Fragment>
        ],
        WRONG: [
          <Fragment>
            RM0. Oops, you did a little mistake. This week's quiz ends here with
            a total of {points} for you. Winners are chosen at the end of the
            week. If you win the {prize}, 123 will call you!
          </Fragment>
        ]
      };
    }

    return {
      CORRECT: [
        <Fragment>
          RMO. Wonderful! Every correct answer gets you closer to be one of the{" "}
          {prize} winners!
          <br />
          Total: {points} pts
        </Fragment>,
        <Fragment>
          RM0. You are on fire!
          <br />
          Make sure to answer all 30
          <br />
          questions of the week and earn
          <br />
          the maximum points
          <br />{prize} are waiting!
        </Fragment>,
        <Fragment>
          RM0. Very good!
          <br />
          Remember, you can be one of the
          <br />
          {prize} WINNERS!
        </Fragment>,
        <Fragment>
          RM0 Excellent!
          <br />
          Every correct answer gets you
          <br />
          closer to become a WINNER!
        </Fragment>,
        <Fragment>
          RM0. Bravo!
          <br />
          {prize} is waiting!
          <br />
          Total: {points} pts
        </Fragment>
      ],
      WRONG: [
        <Fragment>
          RM0. Sorry, your answer is not correct. Make sure to answer all 30
          questions of the week and earn the maximum points.
          <br />
          <br />3 {prize} are waiting!
        </Fragment>,
        <Fragment>
          RM0. No...
          <br />
          But no worries.
          <br />
          Every correct answer gets you closer to be one of the {prize}
          <br />
          winners! Total: {points} pts
        </Fragment>,
        <Fragment>
          RM0. Sorry, your answer is not correct.
          <br />
          Remember, you can be one of the {prize} WINNERS!
        </Fragment>,
        <Fragment>
          RM0. Oops you did a little mistake. Every correct answer gets you
          closer to become a WINNER!
        </Fragment>,
        <Fragment>
          RM0. Wrong option. Never mind!
          <br />
          {prize} is waiting!
          <br />
          Total: {points} pts
        </Fragment>
      ]
    };
  } else {
    if (finalReply) {
      return {
        CORRECT: [
          <Fragment>
            RM0. Cemerlang! Mata terkumpul anda minggu ini adalah sejumlah{" "}
            {points} mata. Pemenang dipilih setiap Ahad malam.Jika anda PEMENANG{" "}
            {prize}, Nantikan Panggilan drp 123.
          </Fragment>
        ],
        WRONG: [
          <Fragment>
            RM0. Anda buat kesilapan kecil. Mata terkumpul anda minggu ini
            adalah sejumlah {points} mata. Pemenang dipilih setiap Ahad
            malam.Jika anda PEMENANG {prize}, Nantikan Panggilan drp 123.
          </Fragment>
        ]
      };
    }

    return {
      CORRECT: [
        <Fragment>
          RMO.Bagus!
          <br />
          Anda lebih hampir menjadi salah
          <br />
          seorg pemenang {prize} dgn setiap jawapan betul!
          <br />
          Jumlah: {points} mata
        </Fragment>,
        <Fragment>
          Anda buat kesilapan kecil. RM0. Semangat anda membara!
          <br />
          Pastikan anda jawab kesemua 30 soalan minggu ini
          <br />
          & perolehi mata maksimum.
          <br />{prize} menanti anda!
        </Fragment>,
        <Fragment>
          RM0. Sangat baik! Ingat, anda mungkin
          <br />
          salah seorang PEMENANG {prize} itu!
        </Fragment>,
        <Fragment>
          RM0. Cemerlang! Setiap jawapan
          <br />
          betul mencerahkan peluang anda jadi PEMENANG!
        </Fragment>,
        <Fragment>
          RM0. Syabas!
          <br />
          {prize} sedang menunggu!
          <br />
          Jumlah:{points} mata
        </Fragment>
      ],
      WRONG: [
        <Fragment>
          RM0. Maaf, jawapan anda tidak betul.
          <br />
          Pastikan anda jawab kesemua 30
          <br />
          soalan minggu ini & perolehi
          <br />
          mata maksimum.
          <br />3 {prize} menanti anda!
        </Fragment>,
        <Fragment>
          RM0. Salah...
          <br />
          Tapi jangan risau.
          <br />
          Anda lebih hampir menjadi salah seorg pemenang
          <br />
          {prize} dgn setiap jawapan betul!
          <br />
          Jumlah: {points} mata
        </Fragment>,
        <Fragment>
          RM0. Maaf, jawapan anda tidak betul. Ingat, anda
          <br />
          mungkin salah seorang PEMENANG {prize} itu!
        </Fragment>,
        <Fragment>
          RM0. Anda buat kesilapan kecil.
          <br />
          Setiap jawapan betul mencerahkan peluang anda jadi
          <br />
          PEMENANG!
        </Fragment>,
        <Fragment>
          RM0. Pilihan salah.
          <br />
          Tidak mengapa!
          <br />
          {prize} sedang menunggu!
          <br />
          Jumlah: {points} mata
        </Fragment>
      ]
    };
  }
}

class ReplyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latestReply: null,
      lastTextRandomArrayIndex: null,
      lastContentRandomArrayIndex: null
    };
  }

  getRandomElementFromContentArray(array) {
    const length = array.length;
    const random_index = Math.round(Math.random() * (length - 1));

    if (this.state.latestReply !== this.props.result) {
      this.setState({
        lastContentRandomArrayIndex: random_index
      });
    }
    return array[this.state.lastContentRandomArrayIndex];
  }

  getContent() {
    const { result, finalReply } = this.props;
    const lang = getLanguageText();
    let content_replies_map = getContentRepliesMap(lang, finalReply);
    const content_replies = content_replies_map[result];
    if (!content_replies) return null;
    return this.getRandomElementFromContentArray(content_replies);
  }

  showNextQuestion() {
    this.setState({
      latestReply: null
    });
    this.props.showNextQuestion();
  }

  render() {
    const { classes, showNextQuestion, finalReply } = this.props;
    if (this.state.latestReply !== this.props.result) {
      this.setState({
        latestReply: this.props.result
      });
    }
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems={"center"}
        spacing={5}
        style={{ paddingTop: "20px" }}
      >
        <Grid item>
          <img
            src={hotlink_logo}
            alt="hotlink_mega_logo"
            className={classes.logo}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.typography_content} component="h1">
            {this.getContent()}
          </Typography>
        </Grid>
        {!finalReply ? (
          <Grid item>
            <Fab
              variant="extended"
              aria-label="Next Question"
              className={classes.fab}
              onClick={() => this.showNextQuestion()}
            >
              {getTextsProperty(text => text.reply_card.button_text)}
            </Fab>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

ReplyCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReplyCard);
