import React from "react";
import { SwipeableDrawer } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/styles";
import { DrawerContent } from "../../custom_components/drawer/drawer_content";
import { Toolbar } from "../../custom_components/toolbar";

const makeGeneralStyles = makeStyles(
  theme =>
    createStyles({
      list: {},
      header: {
        height: props => (props.fullscreen ? 0 : theme.toolbar.height),
        backgroundColor: "#ffff"
      },
      footer: {
        height: props => (props.fullscreen ? 0 : theme.footer.height)
      },
      content: {
        height: props =>
          props.fullscreen
            ? "100%"
            : `calc(100% - ${theme.toolbar.height}px - ${theme.footer.height}px)`
      }
    }),
  { flip: null }
);

const makeDrawerStyles = makeStyles(
  theme =>
    createStyles({
      root: {},
      paper: {
        position: "fixed",
        top: props => `${props.getViewportOFfset().top}px`,
        left: props => `${props.getViewportOFfset().left}px`,
        // this is done for the direction change since dynamic css props dont flip
        right: props => `${props.getViewportOFfset().left}px`,
        width: "70vw",
        maxWidth: 400,
        maxHeight: 1080,
        backgroundColor: "#ffff"
      }
    }),
  { flip: null }
);

export function Skeleton(props) {
  const classes = makeGeneralStyles(props);
  const drawer_classes = makeDrawerStyles(props);

  const { toolbar_visible } = props;
  return (
    <div style={{ zIndex: 2, height: "100%", width: "100%" }}>
      <SwipeableDrawer
        open={props.drawer_open}
        onClose={() => props.setDrawerStatus(false)}
        onOpen={() => props.setDrawerStatus(true)}
        disableBackdropTransition
        disableSwipeToOpen={!toolbar_visible}
        classes={drawer_classes}
      >
        <div role="presentation">
          {/* Drawer Content */}
          <DrawerContent closeDrawer={() => props.setDrawerStatus(false)} />
        </div>
      </SwipeableDrawer>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        style={{ height: "100%" }}
      >
        {/* header row */}
        <Grid
          container
          item
          justify="flex-start"
          alignItems="stretch"
          className={classes.header}
        >
          {/* Toolbar Content */}
          {toolbar_visible ? (
            <Toolbar openDrawer={() => props.setDrawerStatus(true)} />
          ) : null}
        </Grid>
        {/* content row */}
        <Grid
          container
          item
          justify="flex-start"
          alignItems="stretch"
          className={classes.content}
        >
          {/* App Pages Are rendered here */}
          {props.children}
        </Grid>
        {/* footer row */}
      </Grid>
    </div>
  );
}
