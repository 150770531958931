import React from "react";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { DrawerRow } from "./drawer_row";
import { getDrawerItems } from "./drawer_items";

import hotlinklogo from "../../assets/images/new_menu_logo.png";

const makeGeneralStyles = makeStyles(theme => {
  return {
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 16,
      paddingRight: 20,
      color: "white"
    },
    header: {
      height: theme.toolbar.height,
      padding: 0
    },
    row: {
      height: 30,

      color: "white"
    },
    button: {
      color: "black"
    },
    logo: {
      margin: "auto",
      width: 100
    },
    divider: {
      backgroundColor: "white",
      paddingLeft: 16
    }
  };
});

export function DrawerContent(props) {
  const classes = makeGeneralStyles();

  return (
    <List component="nav" className={classes.list} aria-label="Menu Items">
      <ListItem className={classes.header}>
        <Grid container item justify="flex-start">
          <img className={classes.logo} src={hotlinklogo} alt="hotlink-logo" />
        </Grid>
        <Grid
          container
          item
          justify="flex-end"
          alignItems="center"
          className={classes.header}
        >
          <IconButton
            onClick={props.closeDrawer}
            className={classes.button}
            aria-label="Close Menu"
          >
            <Close fontSize="large" />
          </IconButton>
        </Grid>
      </ListItem>
      {getDrawerItems().map(item => (
        <DrawerRow {...item} key={item.id} closeDrawer={props.closeDrawer} />
      ))}
    </List>
  );
}
