import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import hotlinklogo from "../assets/images/new_menu_logo.png";

const useStyles = makeStyles(
  theme => {
    return {
      button: {
        color: "black"
      },
      logo: {
        margin: "auto",
        width: 100
      }
    };
  },
  { flip: null }
);

export function Toolbar(props) {
  function handleMenuClick() {
    if (props.openDrawer) {
      props.openDrawer();
    }
  }

  const classes = useStyles();

  return (
    <Fragment>
      {/* first item (dummy) */}
      {/* second item {text} */}
      <Grid container item xs={10} justify="flex-start" alignItems="flex-start">
        <div>
          <img className={classes.logo} src={hotlinklogo} alt="hotlink-logo" />
        </div>
      </Grid>
      {/*  third item (menu button) */}
      <Grid item xs={1} />
      <Grid container item xs={1} justify="flex-end">
        <IconButton
          className={classes.button}
          onClick={handleMenuClick}
          aria-label="Menu"
        >
          <Menu fontSize="large" />
        </IconButton>
      </Grid>
    </Fragment>
  );
}
