import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import moment from "moment";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { TimelineOutlined, CalendarTodayOutlined } from "@material-ui/icons";
import { StarBorderOutlined } from "@material-ui/icons";

import { getTextsProperty } from "../../localization/get_texts_property";
import state from "../../services/state";

import hotlink_logo from "../../assets/images/hotlink_new_logo.png";
import { MenuPageSkeleton } from "../../custom_components/menu_page_skeleton";
import { tryGet } from "../../utils/try_get";

//Date was 20/09/2019
const WEEK_OF_PROJECT = 203;

//Week 203 of project started at 2019/09/16
let startOfWeek203 = moment("2019/09/16");

const makeGeneralStyles = makeStyles(theme => {
  return {
    container: {
      overflowY: "scroll",
      height: "100%",
      width: "100%",
      padding: "5%"
    },
    logo: {
      width: 100,
      ["@media (max-width:320px)"]: {
        width: 80
      },
      paddingTop: 20
    },
    hr: {
      maxWidth: "380px",
      width: "100%"
    },
    content: {
      fontSize: 15,
      fontWeight: 500
    },
    largeIcon: {
      width: 50,
      height: 50,
      borderBottom: "1px solid white"
    },
    smallIcon: {
      width: 20,
      height: 20,
      color: "white",
      paddingRight: 5
    },
    logoContainer: {
      paddingTop: 20
    },
    pointsFontSize: {
      fontSize: 13
    },
    button: {
      borderRadius: 30,
      width: theme.buttonWidth.width,
      marginTop: 15,
      height: 60,
      fontWeight: 700,
      textTransform: "none",
      fontSize: 20,
      backgroundColor: "white",
      color: "red"
    },
    text: {
      maxWidth: 350
    },
    textContent: {
      ["@media (max-width:320px)"]: {
        fontSize: "1.1rem"
      }
    }
  };
});

function Account(props) {
  useEffect(() => {
    props.setToolbarStatus(false);
    props.setFullscreen(true);
    return () => {
      props.setFullscreen(false);
      window.location.reload();
    };
  }, []);

  const classes = makeGeneralStyles();
  const msisdnString = state.app_data.msisdn;
  const weeklyPointsString = tryGet(
    o => state.app_data.user.weeklyPoints || " - "
  );

  const currentDate = moment();

  const currentWeek =
    currentDate.diff(startOfWeek203, "week") + WEEK_OF_PROJECT;

  return (
    <MenuPageSkeleton id="my_account">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.container}
        spacing={1}
      >
        <Grid item zeroMinWidth>
          <img
            src={hotlink_logo}
            alt="hotlink_mega_logo"
            className={classes.logo}
          />
        </Grid>
        {/*<Grid item xs={1}/>*/}
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item zeroMinWidth>
              <Typography variant="h6" align="center">
                {getTextsProperty(text => text.account_page.welcome_message)}
              </Typography>
            </Grid>
            <Grid item zeroMinWidth>
              <Typography variant="h5" align="center">
                {msisdnString}
              </Typography>
            </Grid>
            <Grid item zeroMinWidth className={classes.hr}>
              <hr style={{ borderStyle: "solid" }}></hr>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item zeroMinWidth>
              <Typography
                variant="h6"
                align="center"
                className={classes.content}
              >
                {getTextsProperty(text => text.account_page.statistics)}
              </Typography>
            </Grid>
            <Grid item zeroMinWidth>
              <TimelineOutlined className={classes.largeIcon} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item zeroMinWidth>
              <Typography variant="h4" align="center">
                {weeklyPointsString}
              </Typography>
            </Grid>
            <Grid container justify="center">
              <StarBorderOutlined className={classes.smallIcon} />
              <Typography
                variant="h6"
                align="center"
                className={classes.pointsFontSize}
              >
                {getTextsProperty(text => text.account_page.weekly_points)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item zeroMinWidth>
              <Typography variant="h4" align="center">
                {currentWeek}
              </Typography>
            </Grid>
            <Grid container justify="center">
              <CalendarTodayOutlined className={classes.smallIcon} />
              <Typography
                variant="h6"
                align="center"
                className={classes.pointsFontSize}
              >
                {getTextsProperty(text => text.account_page.week)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MenuPageSkeleton>
  );
}

export default Account;
