import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";
import { history } from "../services/router";
import { DrawerRow } from "./drawer/drawer_row";
import { getDrawerItems } from "./drawer/drawer_items";
// import image from '../assets/images/background.jpg'

const makeGeneralStyles = makeStyles(theme => {
  return {
    div: {
      color: "white",
      height: "100%",
      width: "100%",
      zIndex: 3
    },
    header: {
      height: theme.toolbar.height
    },
    content: {
      height: `calc(100% - ${theme.toolbar.height}px )`
    },
    button: {
      color: "white"
    }
  };
});

export function MenuPageSkeleton(props) {
  const [status, setStatus] = useState(true);
  const classes = makeGeneralStyles(props);

  const drawer_row_props = getDrawerItems().find(x => x.id === props.id);
  delete drawer_row_props.divider;
  delete drawer_row_props.onClick;

  return (
    <Fade in={status} mountOnEnter unmountOnExit>
      <div className={classes.div}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          style={{ height: "100%" }}
        >
          {/* header row */}
          <Grid
            container
            item
            justify="flex-start"
            alignItems="stretch"
            className={classes.header}
          >
            {/* Toolbar Content */}
            {/* first item (dummy) */}
            <Grid item xs={1} />
            <Grid item container xs={10} justify="center">
              <DrawerRow {...drawer_row_props} renderAsList />
            </Grid>
            {/*  third item (menu button) */}
            <Grid container item xs={1} justify="flex-end">
              <IconButton
                className={classes.button}
                onClick={() => {
                  setStatus(false);
                  history.goBack();
                }}
                aria-label="Menu"
              >
                <Close fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          {/* content row */}
          <Grid
            container
            item
            justify="flex-start"
            alignItems="stretch"
            className={classes.content}
          >
            {/* App Pages Are rendered here */}
            {props.children}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
}
