import { getLanguageKey } from './language';
import texts from './texts.json';

export function getTextsProperty(getPropertyFunction) {
    try {
        const key = getLanguageKey();
        const text = getPropertyFunction(texts);
        return text[key];
    } catch (error) {
        console.error(error);
        return '';
    }
}
