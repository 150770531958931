import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { getTextsProperty } from "../localization/get_texts_property";
import { toURL } from "../services/router";

import hotlink_logo from "../assets/images/hotlink_new_logo.png";

const makeGeneralStyles = makeStyles(theme => {
  return {
    container: {
      height: "100%",
      color: "white"
    },
    logo: {
      width: 100
    },
    homePageMessage: {
      whiteSpace: "noWrap",
      overflow: "visible",
      fontWeight: 700
    },
    logoContainer: {
      paddingTop: 20
    },
    button: {
      borderRadius: 30,
      width: theme.buttonWidth.width,
      marginTop: 15,
      height: 60,
      fontWeight: 700,
      textTransform: "none",
      fontSize: 20,
      backgroundColor: "white",
      color: "red"
    },
    text: {
      maxWidth: 350
    },
    textContent: {
      ["@media (max-width:320px)"]: {
        fontSize: "1.1rem"
      }
    },
    dialogContent: {
      fontSize: 15,
      overflow: "visible",
      flexWrap: "wrap"
    },
    largeIcon: {
      width: 100,
      height: 100
    }
  };
});

function handleStartGame() {
  toURL("/questions");
}

function LandingPage(props) {
  const classes = makeGeneralStyles();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    props.setToolbarStatus(true);
    return () => {
      props.setToolbarStatus(false);
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <img
          src={hotlink_logo}
          alt="hotlink_mega_logo"
          className={classes.logo}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item className={classes.text}>
        <Typography variant="h6" align="center" className={classes.textContent}>
          {getTextsProperty(text => text.landing_page.pre_game_text)}
        </Typography>
      </Grid>
      <Grid item xs={1} />
      <Grid item>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleStartGame}
        >
          {getTextsProperty(text => text.landing_page.buttonText)}
        </Button>
      </Grid>
    </Grid>
  );
}

export default LandingPage;
