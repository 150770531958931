import React from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { language } from '../services/language';


function getHorizontalPosition() {
    const lang = language.getValue();
    return lang === 'AR' ? 'right' : 'left';
}

function getActionStyle() {
    const lang = language.getValue();
    if (lang === 'AR') {
        return {
            marginRight: 'auto',
            marginLeft: -16,
        }
    }
    return {};
}

function getMargin(theme) {
    const lang = language.getValue();
    return lang === 'AR' ? { marginLeft: theme.spacing(1) } : { marginRight: theme.spacing(1) };
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[700],
    },
    info: {
        backgroundColor: blue[700],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        ...getMargin(theme),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    root: language.getDirectionStyle(),
    action: getActionStyle(),
});

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            classes={{root:classes.root,action:classes.action}}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classNames(classes.action, classes.close,)}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}


const styles2 = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
});

const notify = {
    success: function ({ message, auto_hide_duration = 3000 }) {
        this.setState({
            variant: 'success',
            message,
            auto_hide_duration,
            open: true
        })
    },
    error: function ({ message, auto_hide_duration = 3000 }) {
        this.setState({
            variant: 'error',
            message,
            auto_hide_duration,
            open: true
        })
    },
    info: function ({ message, auto_hide_duration = 3000 }) {
        this.setState({
            variant: 'info',
            message,
            auto_hide_duration,
            open: true
        })
    },
    warning: function ({ message, auto_hide_duration = 3000 }) {
        this.setState({
            variant: 'warning',
            message,
            auto_hide_duration,
            open: true
        })
    },
    hide: function () {
        this.setState({
            open: false
        })
    }
}

class CustomizedSnackbars extends React.Component {
    constructor(props) {
        super(props)

        this.handleClose = this.handleClose.bind(this);


        this.state = {
            open: false,
            message: '',
        };
    }

    componentDidMount() {
        notify.success = notify.success.bind(this);
        notify.error = notify.error.bind(this);
        notify.info = notify.info.bind(this);
        notify.warning = notify.warning.bind(this);
        notify.hide = notify.hide.bind(this);
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };

    getSnackbarContent() {
        const { message, variant } = this.state;
        const { classes } = this.props;
        const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
        return (
            <MySnackbarContentWrapper
                variant={variant}
                className={classes.margin}
                message={message}
                onClose={this.handleClose}
            />
        )
    }

    render() {
        const { auto_hide_duration } = this.state;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: getHorizontalPosition(),
                    }}
                    open={this.state.open}
                    autoHideDuration={auto_hide_duration}
                    onClose={this.handleClose}
                >
                    {this.getSnackbarContent()}
                </Snackbar>
            </div>
        );
    }
}

const Notifications = withStyles(styles2)(CustomizedSnackbars);

export {
    notify,
    Notifications,
}
