export const config = {
    available_languages: {
        EN: {
            description: 'English',
            direction: 'ltr'
        },
        MY: {
            description: 'Bahasa Malay',
            direction: 'ltr'
        }
    },
    default_language: 'EN',
    api_url: "https://quiz.hotlinkmega.com/api/" //process.env.REACT_APP_API_URL //'http://78.137.97.174:3004/'
};
