import axios from 'axios';

import state from '../state';
import storage from '../storage';
import { get_requests } from './getRequests';
import { post_requests } from './postRequests';

function setToken(token) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + token
    console.log('Token to headers -- 1');
}

const api = {
    setToken,
    superLogin,
    ...get_requests,
    ...post_requests,
}

async function superLogin(msisdn, pin) {
    let res;
    try {
        res = await api.pinLogin({ msisdn, pin });
        if (res.success) {
            storage.setItem('token', res.token);
            setToken(res.token);
            state.app_data = res.app_data;
        }
    } catch (error) {
        console.error(error);
        res = { success: false, error: 'invalid_api_response' };
    } finally {
        return res;
    }
}

export default api