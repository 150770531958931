const getItem = key => {
    try {
        return JSON.parse(localStorage[key]);
    } catch (error) {
        console.info(`Property ${key} does not exist in local storage.`);
    }
}

const setItem = (key, data) => {
    localStorage[key] = JSON.stringify(data)
}

const storage = { getItem, setItem }

export default storage;