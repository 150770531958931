import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

let dialog_ref;

function NullComponent() {
    return null;
}

const styles = {
    root: {
        textAlign:'center',
        color:'red'
    }
};

class GeneralDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            title: '',
            Content: NullComponent,
        };
    }

    componentDidMount() {
        dialog_ref = this;
    }

    render() {
        const { classes } = this.props;
        const { open, title, Content} = this.state;
        return (
            <Dialog
                aria-labelledby="scroll-dialog-title"
                open={open}
                onBackdropClick={hide}
                maxWidth = {'xs'}
                scroll= {'paper'}
            >
                <DialogTitle id="dialog-title" classes={{root:classes.root}} >{title}</DialogTitle>
                <Content />
            </Dialog>
        );
    }
}

function show({ title, Content }) {
    dialog_ref.setState({
        title,
        Content,
        open: true
    });
}

function hide() {
    dialog_ref.setState({
        title: '',
        Content: NullComponent,
        open: false
    });
}

const dialog = {
    show,
    hide
};
export default withStyles(styles)(GeneralDialog);
export {dialog}
