const events = {};

function subscribe(event, clb) {
    events[event] = events[event] || [];
    events[event].push(clb);
    let unsubscribed = false;
    return {
        unsubscribe: () => {
            if (unsubscribed) return;
            unsubscribed = true;
            const index = events[event].indexOf(clb);
            events[event].splice(index, 1);
        }
    };
}

function emit(event, data) {
    if (!events[event]) return;
    events[event].forEach(clb => {
        clb(data);
    });
}

const exported_functions = {
    subscribe,
    emit
};

export { exported_functions as events };
