import axios from 'axios';

import { loader } from '../../components/loader';
import { notify } from '../../components/notifications';
import {config} from '../../config';
import { getErrorMessage } from '../../services/error_messages';
import storage from "../storage";

const post_requests = [
    { url: 'reply',     name: 'reply',    values:['answer','question_id', 'language'] },
    { url: 'get-state',  name: 'getState', values:['language']   },
];

// const homeDir = config.api_url;
const post_requests_object = {};
// const homeDir = 'http://localhost:3001/';
const homeDir = config.api_url;


function setToken(token) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + token
    console.log('Token to headers --2');
}

const token = storage.getItem('token');
if (token){
    setToken(token);
}else{
    console.log('no token to attach in headers');
}



post_requests.forEach(request => {
    const config = request.config || {}
    post_requests_object[request.name] = async (options) => {
        let url = homeDir + request.url;
        const postBody = {}
        if (options) {
            request.values.forEach(value => {
                postBody[value] = options[value]
            })
        }
        let response = { data: {} };
        try {
            loader.show();
            response = await axios.post(url, postBody, config)
            if (response && response.data && !response.data.success && response.data.error) {
                const error_message = getErrorMessage(response.data.error);
                notify.error({ message: error_message });
            }
        } catch (error) {
            response.data.success = false;
            // this means that we cannot contact the server
            if (!error.response) {
                notify.error({ message: getErrorMessage('network_error') });
                response.data.error = 'network_error';
            } else {
                console.error(error);
                const message_code = error.response.data.error;
                response.data.error = message_code;
                notify.error({ message: getErrorMessage(message_code) });
            }
        } finally {
            loader.hide();
            return response.data;
        }
    }
});

export {
    post_requests_object as post_requests,
}
