import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { dialog } from "../../components/dialog";
import api from "../../services/api/api";
import state from "../../services/state";

import {getTextsProperty} from "../../localization/get_texts_property";

import { toURL } from "../../services/router";
import {
  getLanguageText,
  getAvailableLanguages,
  setLanguage
} from "../../localization/language";

const URL = 'http://hotlinkmega.com/';

export function getDrawerItems() {

  return [
    {
      id: "home",
      text: "Home",
      divider: false,
      close_drawer: true,
      onClick: () => {
        window.location = URL
      }
    },
    {
      id: "my_account",
      text: "My Account",
      divider: false,
      close_drawer: true,
      onClick: () => {toURL("/account")}
    },
    {
      text: getLanguageText(),
      id: getLanguageText(),
      alt: 'languages_icon',
      divider: false,
      onClick: () => {
        dialog.show({
          title: getTextsProperty(x => x.drawer.language_selector),
          Content: LanguageSelectDialog
        });
      }
    }
  ];
}

function LanguageSelectDialog() {
  const languages = getAvailableLanguages();
  async function handleLangSelect(lang_key) {

    const api_res = await api.changeLanguage({lang: lang_key});

    if(!api_res.success && api_res.error === 'no_lang'){
      dialog.hide();
    }else{
      setLanguage(lang_key);
      dialog.hide();
      state.app_data = null;
      window.location.reload();
    }
  }

  return (
    <List>
      {Object.keys(languages).map(lang_key => {
        const text = languages[lang_key].description;
        return (
          <ListItem
            button
            key={lang_key}
            onClick={() => handleLangSelect(lang_key)}
          >
            <ListItemText>{text}</ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}
