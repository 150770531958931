import { text} from '../locales';
import {config} from "../config";

const { available_languages } = config;


function getErrorMessage(error) {
    if (!error || !available_languages.available_languages.EN.hasOwnProperty(error)) return text('server_error')
    return text(error)
}

export {
    getErrorMessage
}
