import axios from 'axios';

import { loader } from '../../components/loader';
import { notify } from '../../components/notifications';
import {config} from '../../config';
import { getErrorMessage } from '../../services/error_messages';
import storage from "../storage";

const get_requests = [
    { url: 'auth?msisdn={msisdn}', name: 'authUser', },
    { url: 'get-state',             name: 'getState',   },
    { url: 'change-lang?lang={lang}', name: 'changeLanguage', },
];

// const homeDir = config.api_url;
const get_requests_object = {};
// const homeDir = 'http://localhost:3001/';
const homeDir = config.api_url;

function setToken(token) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
    console.log('Token to headers --2');
}

const token = storage.getItem('token');
if (token){
    setToken(token);
}else{
    console.log('no token to attach in headers');
}

get_requests.forEach(props => {
    get_requests_object[props.name] = async options => {
        let url = homeDir + props.url;
        if (options) {
            Object.keys(options).forEach(param => {
                url = url.replace(`{${param}}`, options[param])
            })
        }
        url = url.replace(/\{.+?\}/g, '')
        let response = { data: {} };
        try {
            loader.show();
            console.log('API Url:',url);
            response = await axios.get(url)
            if (response && response.data && !response.data.success && response.data.error) {
                const error_message = getErrorMessage(response.data.error);
                notify.error({ message: error_message });
            }
        } catch (error) {
            response.data.success = false;
            console.error(error);
            // this means that we cannot contact the server
            if (!error.response) {
                if (!['getState'].includes(props.name)) {
                    // we want to show that in every request except get-state
                    // get-state should handle this differently (with a dialog)
                    notify.error({ message: getErrorMessage('network_error') });
                }
                response.data.error = 'network_error'
            } else {
                const message_code = error.response.data.error;
                response.data.error = message_code;
                notify.error({ message: getErrorMessage(message_code) });
            }
        } finally {
            loader.hide();
            console.log('API Response',JSON.stringify(response.data));
            return response.data;
        }
    }
});

export {
    get_requests_object as get_requests,
}

