import React from 'react';

const bm = {
    // ACCOUNT
    account_header: 'حسابي',
    account_chances: 'فرصي',
    account_level: 'المستوى',
    account_name: 'الاسم',
    account_edit: 'تعديل',
    account_language: 'اللغة',
    account_language_value: 'العربية',
    account_bronze: 'البرونزي',
    account_silver: 'الفضي',
    account_gold: 'الذهبي',
    account_platinum: 'البلاتيني',
    account_diamond: 'الماسي',
    account_language_change: 'تم تغيير اللغة بنجاح',
    account_name_submit: 'تم تعديل الاسم بنجاح',
    dialogue_title: 'تأكيد تغيير اللغة',
    dialogue_content: 'هل انت متأكد انك تريد تغيير اللغة',
    dialogue_cancel: 'إلغاء',
    dialogue_confirm: 'تأكيد',
    edit_name_header: 'الاسم',
    edit_name_cancel: 'إلغاء',
    edit_name_submit: 'تأكيد',
    // MENU
    menu_how_to: 'كيفية اللعب',
    menu_prizes: 'الجوائز',
    menu_winners: 'الرابحين',
    menu_faq: 'الأسئلة الشائعة',
    menu_terms: 'الشروط والأحكام',
    menu_privacy: 'سياسة الخصوصية',
    menu_account: 'حسابي',
    // QUESTIONS
    question_correct: 'إجابة صحيحة',
    question_wrong: 'إجابة خاطئة',
    no_questions: 'لقد أجبت على باقة أسئلة اليوم! سننتظرك مع باقة أسئلة جديدة لتجمع فرص اكثر للسحوبات!',
    question_header: '{question_total} السؤال {question_index} من',
    question_chances: 'لـ {chance_number} فرصة',
    // WELCOME
    welcome_header: 'غير حياتك',
    welcome_wait: 'برجاء الانتظار..',
    // SHORTCODES
    shortcode_header: 'حقق حلمك',
    shortcode_instructions: <span>للمشاركة أرسل كلمة<span className='orange'> JOIN </span><br />على أحد الأرقام التالية:</span>,
    shortcode_state: 'الدولة',
    shortcode_provider: 'مشغل الخدمة',
    shortcode_number: 'الرقم',
    shortcode_footer_1: 'مشترك بالفعل؟',
    shortcode_footer_2: 'استخدم الرابط الذي وصلك للدخول لحسابك',
    // MESSAGES
    server_error: 'عذرا ! حدث خطأ فني',
    network_error: 'تعذر الاتصال',
    login_error: 'يبدو أن هناك مشكلة! تأكد انك متصل بالانترنت',
    login_try_again: 'إعادة المحاولة',
    // HOW TO PLAY
    how_to_play_1: 'عند اشتراكك ستصلك فرص في السحوبات ورابط يمكنك من خلال الضغط عليه أن تستمتع بباقة من 3 أسئلة. المزيد من الفرص و باقة جديدة من الأسئلة ستصلك كل مرة يتم فيها تجديد اشتراكك.',
    how_to_play_2: 'يمكنك زيادة فرصك في السحوبات كلما أجبت بشكل صحيح على أسئلة الباقة.',
    // PRIZES
    dream_prizes: '$3,000,000 دولار أمريكي بالمجموع، لتحقيق أحلام أكثر هذه السنة.',
    // WINNERS
}

export default bm;
