import React, { Fragment } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const makeGeneralStyles = makeStyles(
  theme =>
    createStyles({
      list_item: {
        paddingLeft: 0,
        justifyContent: "center"
      },
      list_text: {
        flex: props => (props.renderAsList ? "none" : "1 1 auto")
      },
      image: {
        height: 30,
        width: 30,
        marginRight: 10
      },
      divider: {
        backgroundColor: "white",
        paddingLeft: 16
      }
    }),
  { flip: props => false }
);

export function DrawerRow(props) {
  const classes = makeGeneralStyles(props);
  const page = window.location.pathname;
  const account_page = page.match(/(^|\W)account($|\W)/);
  return (
    <Fragment>
      <ListItem
        className={classes.list_item}
        button={!props.renderAsList}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
            if (props.close_drawer) props.closeDrawer();
          }
        }}
      >
        <ListItemText
          style={{ color: account_page ? "white" : "black" }}
          className={classes.list_text}
        >
          <strong>{props.text}</strong>
        </ListItemText>
      </ListItem>
      {props.divider ? <Divider className={classes.divider} /> : null}
    </Fragment>
  );
}
