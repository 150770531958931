import React, { useEffect } from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

import state from "../services/state";
import hotlink_logo from "../assets/images/hotlink_new_logo.png";

import { getTextsProperty } from "../localization/get_texts_property";
import Button from "@material-ui/core/Button";

let landing_page_url =  'https://hotlinkmega.com';
if (process.env.REACT_APP_LANDING_PAGE_URL) landing_page_url = process.env.REACT_APP_LANDING_PAGE_URL;

const URL = 'https://hotlinkmega.com';   // "http://hotlinkmega.com/join";
const HOME_URL = "https://hotlinkmega.com/content-selection";

const makeGeneralStyles = makeStyles(theme => {
  return {
    container: {
      height: "100%",
      color: "white"
    },
    logo: {
      width: 100
    },
    logoContainer: {
      paddingTop: 20
    },
    homePageMessage: {
      whiteSpace: "noWrap",
      overflow: "visible",
      fontWeight: 700
    },
    button: {
      borderRadius: 30,
      width: theme.buttonWidth.width,
      marginTop: 15,
      height: 60,
      fontWeight: 700,
      textTransform: "none",
      fontSize: 15,
      backgroundColor: "white",
      color: "red"
    },
    text: {
      maxWidth: 350
    },
    textContent: {
      ["@media (max-width:320px)"]: {
        fontSize: "1.1rem"
      }
    }
  };
});

function renderMessage(userState, userSubscription, userGameStarted, classes) {
  // console.log('HOME_URL:',HOME_URL);
  // console.log('REACT_APP_LANDING_PAGE_URL:',process.env.REACT_APP_LANDING_PAGE_URL);

  switch (userState) {
    case "SUB_INVALID_SUB_KEYWORD":
      return (
        <React.Fragment>
          <Typography
            variant="h6"
            align="center"
            className={classes.textContent}
          >
            {getTextsProperty(
              text => text.message_page.SUB_INVALID_SUB_KEYWORD
            )}
          </Typography>
          <Grid item xs={1} />
          <Grid item xs zeroMinWidth align="center">
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => (window.location = URL)}
            >
              {getTextsProperty(text => text.message_page.buttonText)}
            </Button>
          </Grid>
        </React.Fragment>
      );
    case "REJECTEDONGOING_QUIZ_COMPLETED":
      return (
          <React.Fragment>
              <Typography variant="h6" align="center" className={classes.textContent}>
                {getTextsProperty(
                  text => text.message_page.REJECTEDONGOING_QUIZ_COMPLETED
                )}
              </Typography>
            <Grid item xs={1} />
            <Grid
                item
                xs
                zeroMinWidth
                align="center"
            >
              <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => window.location = HOME_URL}
              >
                {getTextsProperty(text => text.message_page.home_page_button)}
              </Button>
            </Grid>
          </React.Fragment>
      );
    case "SUB_HAS_TO_BE_CHARGED":
      return (
          <React.Fragment>
            <Typography variant="h6" align="center" className={classes.textContent}>
              {getTextsProperty(text => text.message_page.SUB_HAS_TO_BE_CHARGED)}
            </Typography>
            <Grid item xs={1} />
            <Grid
                item
                xs
                zeroMinWidth
                align="center"
            >
              <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => window.location = HOME_URL}
              >
                {getTextsProperty(text => text.message_page.home_page_button)}
              </Button>
            </Grid>
          </React.Fragment>
      );
    default:
      break;
  }
}

function MessagePage(props) {
  const classes = makeGeneralStyles();
  const userState = state.app_data.state;
  const userSubscription = state.app_data.user.subscription_status;
  const userGameStarted = state.app_data.user.game_started;

  useEffect(() => {
    props.setToolbarStatus(true);
    return () => {
      props.setToolbarStatus(false);
    };
  }, []);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.container}
    >
      {/*<Grid item xs={1}/>*/}
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.logoContainer}
      >
        <img
          src={hotlink_logo}
          alt="hotlink_mega_logo"
          className={classes.logo}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs zeroMinWidth className={classes.text}>
        {renderMessage(userState, userSubscription, userGameStarted, classes)}
      </Grid>
    </Grid>
  );
}

export default MessagePage;
