import state from './state';


function getValue() {
    const lang = (state && state.app_data && state.app_data.lang) ? state.app_data.lang : 'EN';
    return lang;
}

function getDirectionStyle() {
    const lang = getValue();
    const direction = lang === 'AR' ? { direction: 'rtl' } : { direction: 'ltr' };
    return direction;
}

function getDirectionClass() {
    const lang = getValue();
    const lang_class = lang === 'AR' ? ' rtl' : '';
    return lang_class;

}


export const language = {
    getValue,
    getDirectionStyle,
    getDirectionClass,
}
