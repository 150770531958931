import React from "react";
import { Component } from "react";

import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { deepOrange, lightBlue } from "@material-ui/core/colors";
import {
  getLanguageKey,
  getLanguageText,
  initLanguageService,
  setLanguage
} from "../../localization/language";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { getTextsProperty } from "../../localization/get_texts_property";
import GeneralDialog from "../../components/dialog";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";

import LandingPage from "../landing_page";
import MessagePage from "../message_page";
import Account from "../account/account";
import Questions from "../questions/questions";
import IdleTimer from "react-idle-timer";

//api

import api from "../../services/api/api";
import { events } from "../../services/events";
import state from "../../services/state";
import { getLocation } from "../../services/get_location";
import storage from "../../services/storage";
import { Router, Route, toURL, history, Switch } from "../../services/router";

import background from "../../assets/images/Background_2.jpg";
import acc_background from "../../assets/images/background.jpg";
import mobile_background from "../../assets/images/mobile_background.jpg";

import { PreloadImages } from "../../components/preload_images";
import LoaderComponent from "../../components/loader";
import { Skeleton } from "../../components/Skeleton";

import { tryGet } from "../../utils/try_get";

import "./App.css";

import ReactGa from "react-ga";

const theme = createTheme({
  palette: {
    primary: deepOrange,
    secondary: lightBlue
  },
  toolbar: {
    height: 60
  },
  footer: {
    height: 80
  },
  content: {
    width: 280
  },
  buttonWidth: {
    width: 210
  },
  overrides: {
    MuiTableCell: {
      head: {
        color: "white"
      },
      body: {
        color: "white"
      }
    }
  },
  snackbarIcon: {
    fontSize: 20
  }
});

let app_ref;

class App extends Component {
  constructor(props) {
    super(props);
    //set default language as app language
    initLanguageService();
    this.goToLoginPage = this.goToLoginPage.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
    this.goToMessagePage = this.goToMessagePage.bind(this);
    this.state = {
      drawer_open: false,
      toolbar_visible: false,
      footer_visible: false,
      fullscreen: false,
      network_error_snackbar_open: false,
      user_not_authenticated_error_dialog_open: false,
      userAuthenticated: false
    };

    this.viewport_ref = React.createRef(null);
  }

  async componentDidMount() {
    //Google analytics initialize
    ReactGa.initialize("UA-68612417-1");
    //report page view
    ReactGa.pageview("/landing-page");
    /////////////////////////////////////
    app_ref = this;
    this.events_unsubscribe = events.subscribe("language-change", () => {
      this.forceUpdate();
    });

    window.addEventListener("resize", this.updateDimensions);

    const token = storage.getItem("token");

    const encodedToken = getLocation();

    //Override token if user log in with different unique link (First and last 3 chars must be 5jl and 8k4)
    if (encodedToken) {
      let uniqueLink = encodedToken.slice(0, 3) + encodedToken.slice(-3);
      //Override token
      if (uniqueLink === "5jl8k4") {
        const api_res = await api.authUser({ msisdn: encodedToken });
        if (!api_res.success && api_res.error === "user_not_found") {
          this.showUserNotAuthenticatedErrorDialog();
          return 0;
        } else if (
          !api_res.success &&
          api_res.error === "internal_server_error"
        ) {
          this.showNetworkErrorSnackbar();
          return 0;
        } else {
          const token = api_res.token;
          storage.setItem("token", token);
          api.setToken(token);
          const usersLanguage = tryGet(
            o => api_res.app_data.user.language,
            "invalid language"
          );
          setLanguage(usersLanguage);

          this.setState({
            userAuthenticated: true
          });
          this.authenticateUser();
        }
      }
    }

    if (!token) {
      const api_res = await api.authUser({ msisdn: encodedToken });

      if (!api_res.success && api_res.error === "user_not_found") {
        this.showUserNotAuthenticatedErrorDialog();
        return 0;
      } else {
        const token = api_res.token;
        storage.setItem("token", token);
        api.setToken(token);
        const usersLanguage = tryGet(
          o => api_res.app_data.user.language,
          "invalid language"
        );
        setLanguage(usersLanguage);

        this.setState({
          userAuthenticated: true
        });

        this.authenticateUser();
      }
    }

    toURL("/landing-page");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  async authenticateUser() {
    const token = storage.getItem("token");
    if (!token) {
      toURL("/landing-page");
    } else {
      const userState = await api.getState({ language: getLanguageKey() });

      if (!userState.success) {
        this.showUserNotAuthenticatedErrorDialog();
        return 0;
      }

      let user_state = tryGet(
        o => userState.app_data.state,
        "invalid_user_state"
      );
      const usersLanguage = tryGet(
        o => userState.app_data.user.language,
        "invalid language"
      );
      setLanguage(usersLanguage);

      state.app_data = userState.app_data;

      if (Object.keys(userState.app_data.question).length === 0) {  //questions finished override
        user_state = "REJECTEDONGOING_QUIZ_COMPLETED";
        userState.app_data.state = "REJECTEDONGOING_QUIZ_COMPLETED";
      }

      switch (user_state) {
        case "STARTING":
          this.goToLoginPage();
          break;
        case "IN_PROGRESS":
          this.goToQuestionsPage();
          break;
        case "SUB_INVALID_SUB_KEYWORD":
          this.goToMessagePage();
          break;
        case "REJECTEDONGOING_QUIZ_COMPLETED":
          this.goToMessagePage();
          break;
        case "REJECTEDONGOING_QUIZ":
          this.goToMessagePage();
          break;
        case "SUB_HAS_TO_BE_CHARGED":
          this.goToMessagePage();
          break;
        default:
          break;
      }
    }
  }

  getUserAuthenticationErrorDialog = () => {
    // this is handled differently because it mounts as a direct child of body
    return (
      <Dialog
        open={this.state.user_not_authenticated_error_dialog_open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getTextsProperty(
              text => text.landing_page.user_authentication_error
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  goToLoginPage() {
    toURL("/landing-page");
  }

  goToMessagePage() {
    toURL("/messages");
  }

  goToQuestionsPage() {
    toURL("/questions");
  }

  showNetworkErrorSnackbar() {
    this.setState({ network_error_snackbar_open: true });
  }

  showUserNotAuthenticatedErrorDialog() {
    this.setState({ user_not_authenticated_error_dialog_open: true });
  }

  retryUserAuthentication() {
    this.setState(
      { network_error_snackbar_open: false },
      this.authenticateUser
    );
  }

  getNetWorkErrorSnackbar() {
    return (
      <Snackbar
        open={this.state.network_error_snackbar_open}
        onClose={() => this.retryUserAuthentication()}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          style={{ backgroundColor: "red" }}
          message={
            <span>
              <ErrorIcon style={{ marginRight: "4px", fontSize: "15px" }} />
              {getTextsProperty(text => text.landing_page.network_error)}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => this.retryUserAuthentication()}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }

  setDrawerStatus = status => {
    this.setState({ drawer_open: status });
  };

  setToolbarStatus = status => {
    this.setState({ toolbar_visible: status });
  };

  setFooterStatus = status => {
    this.setState({ footer_visible: status });
  };

  setFullscreen = value => {
    this.setState({ fullscreen: value });
  };

  getRouter() {
    const passed_functions = {
      setDrawerStatus: this.setDrawerStatus,
      setToolbarStatus: this.setToolbarStatus,
      setFooterStatus: this.setFooterStatus,
      setFullscreen: this.setFullscreen,
      userAuthenticated: this.state.userAuthenticated,
      drawer_open: this.state.drawer_open
    };

    return (
      <Router history={history}>
        <Switch>
          <Route
            path="/landing-page"
            render={routeProps => (
              <LandingPage {...routeProps} {...passed_functions} />
            )}
          />
          <Route
            path="/home"
            render={routeProps => (
              <LandingPage {...routeProps} {...passed_functions} />
            )}
          />
          <Route
            path="/account"
            render={routeProps => (
              <Account {...routeProps} {...passed_functions} />
            )}
          />
          <Route
            path="/questions"
            render={routeProps => (
              <Questions {...routeProps} {...passed_functions} />
            )}
          />
          <Route
            path="/messages"
            render={routeProps => (
              <MessagePage {...routeProps} {...passed_functions} />
            )}
          />
        </Switch>
      </Router>
    );
  }

  getBackground() {
    const { width } = this.state;
    const img =
      width > 620 || width === undefined ? background : mobile_background;
    return (
      <div className="backgroundImageContainer">
        <img
          width={"100%"}
          height={"100%"}
          className="backgroundImage"
          src={img}
          alt="background_image"
        />
      </div>
    );
  }

  getViewportOFfset = () => {
    if (!this.viewport_ref.current) return { top: 0, left: 0 };
    return {
      top: this.viewport_ref.current.offsetTop,
      left: this.viewport_ref.current.offsetLeft
    };
  };

  render() {
    const skeleton_props = {
      drawer_open: this.state.drawer_open,
      toolbar_visible: this.state.toolbar_visible,
      footer_visible: this.state.footer_visible,
      fullscreen: this.state.fullscreen,
      setDrawerStatus: this.setDrawerStatus,
      getViewportOFfset: this.getViewportOFfset
    };
    return (
      <div className="App">
        <LoaderComponent />
        <IdleTimer
          onActive={() => {
            this.authenticateUser();
            this.forceUpdate();
          }}
          debounce={1000}
          timeout={10 * 60 * 10 * 2}
        />
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <div className="viewportContainer">
            <div className="viewport" ref={this.viewport_ref}>
              <GeneralDialog />
              <PreloadImages
                images={[background]}
                postLoadAction={this.authenticateUser}
              >
                <Skeleton {...skeleton_props}>{this.getRouter()}</Skeleton>
                {this.getBackground()}
                {this.getUserAuthenticationErrorDialog()}
                {this.getNetWorkErrorSnackbar()}
              </PreloadImages>
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;

export function updateApp() {
  app_ref.forceUpdate();
}
