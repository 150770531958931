import React from "react";
import { config } from "../config";

import bm from "./bm";
import en from "./en";

const { available_languages } = config;
const lang_jsons_map = {
  EN: en,
  BM: bm
};
const text = (key, vars) => {
  const lang = available_languages.getValue();
  const lang_json = lang_jsons_map[lang];
  let out = lang_json[key];
  if (!out) return "";
  if (out.constructor === Array) {
    out = out.slice();
    for (let i = 0; i < out.length; i++) {
      if (vars && typeof out[i] == "string") {
        Object.keys(vars).forEach(v => {
          if (out[i] === `{${v}}`) {
            out[i] = vars[v];
          }
        });
      }
      out[i] = <span key={i}>{out[i]}</span>;
    }
  } else if (vars) {
    Object.keys(vars).forEach(v => {
      out = out.replace(`{${v}}`, vars[v]);
    });
  }
  return out;
};

export {
    text,
}
